form.search-form {
    input {
        padding: 0 12px 0 40px !important;
        //color: var(--paletteColor8) !important;
    }
    button {
        right: auto !important;
        left: 0;
    }
}

.style-cover {
    position: relative;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(62, 69, 67, 0.7) 0%, rgba(62, 69, 67, 0.34) 100%);
        z-index: 9;
    }
    .wp-block-cover__inner-container {
        z-index: 9;
    }
}

.entry-content>* {
    margin-bottom: 0 !important;
}

.clickable-blocks-home {
    .gb-grid-column {
        cursor: pointer;
        a {
            color: var(--paletteColor8) !important;
        }
    }
}

.l-height {
    line-height: 32px;
}

.custom-b {
    a {
        padding: 0 !important;
        min-height: auto !important;
    }
}

.style-p {
    .wp-block-media-text__content {
        p {
            margin-bottom: 7px !important;
        }
    }
}

.testimonials {
    margin: 0 75px !important;
    .swiper-wrapper {
        .swiper-slide {
            .inner-container {
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                h3 {
                    text-align: center;
                }
                p {
                    padding: 0 70px;
                    font-style: italic;
                }
                p.sub-title {
                    font-size: 18px;
                    font-weight: 600;
                    font-style: normal;
                    color: var(--paletteColor1) !important;
                    span {
                        color: var(--paletteColor3) !important;
                    }
                }
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: var(--paletteColor1) !important;
        &:after {
            font-size: 30px !important;
        }
    }
}

.wp-block-media-text {
    .wp-block-media-text__content {
        padding: 4% 8%;
    }
}

.entries {
    article.entry-card {
        a.ct-image-container {
            height: 230px;
            img {
                height: 100%;
                object-fit: cover;
                box-shadow: 0px 12px 24px 0px rgba(33, 51, 62, 0.2);
            }
        }
    }
}

@include break-below(md) {
    .style-cover {
        min-height: 500px !important;
    }
}

@include break-below(sm) {
    .style-cover {
        min-height: 400px !important;
    }
    .testimonials {
        margin: 0 !important;
    }
    figure.wp-block-image {
        text-align: center;
    }
    .wp-block-image {
        figure.alignright {
            margin: 15px auto;
            float: inherit;
        }
    }
}